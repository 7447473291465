import {
  MsalPluginOptions,
  getOrCreateMsalPlugin,
} from "@/plugins/msal-plugin";
import * as axios from "axios";

// TODO make class

const domainname = "parkeercontrolezones.be";
const stage: "sbx" | "dev" | "prd" = "prd";

const VERSION = 1;
// const BASE_URL = window.location.origin + "/api/";
// const BASE_URL = "https://localhost:443/api/v";
const BASE_URL = `https://api.${stage}.${domainname}/api/v`;
const options: MsalPluginOptions = {
  clientId: process.env.VUE_APP_MSAL_CLIENT_ID!,
  authority: process.env.VUE_APP_MSAL_AUTHORITY!,
  scopes: process.env.VUE_APP_MSAL_SCOPES!.split(";"),
};
const msalPluginInstance = await getOrCreateMsalPlugin(options);
const authBearerTokenHeader = async () => {
  // const header = new Headers();
  // header.append("Authorization", "Bearer " + msalPluginInstance.acquireToken());
  const token = await msalPluginInstance.acquireToken();
  const header = {
    Authorization: `Bearer ${token}`,
  };

  return header;
};

axios.default.defaults.baseURL = BASE_URL + VERSION + "/";

const get = async (path: string) => {
  const response = await axios.default.request({
    method: "GET",
    url: path,
    headers: {
      ...(await authBearerTokenHeader()),
    },
  });
  return response.data;
};

const post = async (path: string, body: any) => {
  const response = await axios.default.post(path, body, {
    headers: {
      ...(await authBearerTokenHeader()),
    },
  });

  return response.data;
};

const put = async (path: string, body: any) => {
  const response = await axios.default.put(path, body, {
    headers: {
      ...(await authBearerTokenHeader()),
    },
  });

  return response.data;
};

const del = async (path: string) => {
  const response = await axios.default.delete(path, {
    headers: {
      ...(await authBearerTokenHeader()),
    },
  });

  return response.data;
};

const patch = async (path: string, body: any) => {
  const response = await axios.default.patch(path, body, {
    headers: {
      ...(await authBearerTokenHeader()),
    },
  });

  return response.data;
};

export const request = {
  get,
  post,
  del,
  put,
  patch,
};
