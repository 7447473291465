/* eslint-disable no-useless-return */
import * as msal from "@azure/msal-browser";
import Vue, { PluginObject, VueConstructor } from "vue";

let msalInstance: msal.PublicClientApplication;
export let msalPluginInstance: MsalPlugin;

declare module "vue/types/vue" {
  interface Vue {
    $msal: MsalPlugin;
  }
}

export interface MsalPluginOptions {
  clientId: string;
  authority: string;
  passwordAuthority?: string;
  knownAuthority?: string;
  scopes: string[];
}

export class MsalPlugin implements PluginObject<MsalPluginOptions> {
  public isAuthenticated = false;
  private pluginOptions: MsalPluginOptions = {
    clientId: "",
    authority: "",
    passwordAuthority: "",
    knownAuthority: "",
    scopes: [],
  };

  private scopes = ["openid", "profile", "offline_access"];

  public async install(
    vue: VueConstructor<Vue>,
    options?: MsalPluginOptions
  ): Promise<void> {
    if (!options) {
      throw new Error("MsalPluginOptions must be specified");
    }

    if (msalInstance) return;

    this.pluginOptions = options;
    await this.initialize(options);
    msalPluginInstance = this;
    vue.prototype.$msal = Vue.observable(msalPluginInstance);
  }

  private async initialize(options: MsalPluginOptions) {
    this.scopes = [...this.scopes, ...options.scopes];
    const msalConfig: msal.Configuration = {
      auth: {
        clientId: options.clientId,
        authority: options.authority,
      },
      system: {
        loggerOptions: {
          loggerCallback: (
            level: msal.LogLevel,
            message: string,
            containsPii: boolean
          ): void => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case msal.LogLevel.Error:
                console.error(message);
                return;
              case msal.LogLevel.Info:
                console.info(message);
                return;
              case msal.LogLevel.Verbose:
                console.debug(message);
                return;
              case msal.LogLevel.Warning:
                console.warn(message);
                return;
              default:
                console.log(message);
                return;
            }
          },
          piiLoggingEnabled: false,
          logLevel: msal.LogLevel.Verbose,
        },
      },
    };
    msalInstance = new msal.PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    this.isAuthenticated = this.getIsAuthenticated();
  }

  public async signIn() {
    try {
      const loginRequest: msal.PopupRequest = {
        scopes: this.scopes,
      };
      const loginResponse: msal.AuthenticationResult =
        await msalInstance.loginPopup(loginRequest);

      this.isAuthenticated = !!loginResponse.account;
      // do something with this?
    } catch (err) {
      // handle error
      // @ts-ignore
      if (err.errorMessage) {
        try {
          const passwordResetResponse: msal.AuthenticationResult =
            await msalInstance.loginPopup({
              scopes: this.scopes,
              authority: this.pluginOptions.passwordAuthority,
            });
          this.isAuthenticated = !!passwordResetResponse.account;
        } catch (passwordResetError) {
          console.error(passwordResetError);
        }
      } else {
        this.isAuthenticated = false;
      }
    }
  }

  public async signOut() {
    await msalInstance.logout();
    this.isAuthenticated = false;
  }

  public async acquireToken() {
    const request = {
      account: msalInstance.getAllAccounts()[0],
      scopes: this.scopes,
    };
    try {
      const response = await msalInstance.acquireTokenSilent(request);

      return response.accessToken;
    } catch (error) {
      console.error(error);

      if (error instanceof msal.InteractionRequiredAuthError) {
        return msalInstance
          .acquireTokenPopup(request)
          .catch((popupError: any) => {
            console.error(popupError);
          });
      }
      return false;
    }
  }

  private getIsAuthenticated(): boolean {
    const accounts: msal.AccountInfo[] = msalInstance.getAllAccounts();
    return accounts && accounts.length > 0;
  }
}

export const getOrCreateMsalPlugin = async (
  options: MsalPluginOptions
): Promise<MsalPlugin> => {
  if (!msalPluginInstance) {
    msalPluginInstance = new MsalPlugin();
    await msalPluginInstance.install(Vue, options);
  }

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event) => {
    // set active account after redirect
    if (
      event.eventType === msal.EventType.LOGIN_SUCCESS &&
      // @ts-ignore
      event?.payload?.account
    ) {
      // @ts-ignore
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  msalInstance.addEventCallback((error) => {
    console.error("error", error);
  });

  // handle auth redired/do all initial setup for msal
  msalInstance
    .handleRedirectPromise()
    .then((authResult) => {
      // Check if user signed in
      const account = msalInstance.getActiveAccount();
      if (!account) {
        // redirect anonymous user to login page
        // msalInstance.loginRedirect();
      }
    })
    .catch((err) => {
      // TODO: Handle errors
      console.error(err);
    });

  return Promise.resolve(msalPluginInstance);
};
