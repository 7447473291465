<template>
  <v-app>
    <v-container>
      <v-row no-gutters class="inverted">
        <v-dialog v-model="showSecurityWarning" persistent max-width="350">
          <v-card>
            <v-card-title class="text-h5">Ongemachtigde toegang</v-card-title>
            <v-card-text>
              Jij hebt geen rechten om deze pagina te bezoeken
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SecurityWarning extends Vue {
  @Prop({ default: false, type: Boolean }) showSecurityWarning!: boolean;
}
</script>
<style scoped></style>
