<template>
  <div>
    <security-warning
      v-if="showSecurityWarning"
      :show-security-warning="showSecurityWarning"
    ></security-warning>
    <router-view v-else />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api/api";
import SecurityWarning from "./components/SecurityWarning.vue";

@Component({
  components: {
    SecurityWarning,
  },
})
export default class App extends Vue {
  private showSecurityWarning: boolean = false;
  async created(): Promise<void> {
    this.showSecurityWarning = !(await api.checkSecurity());
    console.log("Done");
  }
}
</script>
