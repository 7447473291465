import { request } from "@/helpers/request";
import {
  IDepot,
  IGeoPoint,
  IStreet,
  IZone,
  IParameterSet,
  IArea,
  IBikingPoint,
  ICalculation,
  ISubAreaRule,
  ISubAreaRuleListItem,
  IPlanning,
  IActiveParameterSet,
  IRestroom,
  IPriority,
  IStreetLocation,
  ISplitPointLocation,
  IAreaSummary,
  IAreaZone,
  ISubAreaRuleSummary,
  IZonePolygon,
  IImport,
} from "./api-model";
import {
  CreateClusterRuleCommand,
  CreateNewParameterSetCommand,
  FindAreasQuery,
  FindBikingPointsQuery,
  FindCalculationsQuery,
  FindParameterSetQuery,
  FindRoutesQuery,
  FindStreetsInPolygonCommand,
  FindStreetsQuery,
  UpdateParameterSetCommand,
  CreatePlanningCommand,
  UpdatePlanningCommand,
  SetParameterInProductionCommand,
} from "./api-query";
import * as queryString from "query-string";
import { AxiosError } from "axios";

export const api = {
  getRoutes: async (query: FindRoutesQuery): Promise<IZone[]> => {
    const stringified = queryString.stringify(query);
    const path = `/controlzones?` + stringified;
    const response = await request.get(path);

    return response as IZone[];
  },

  getZoneIdsForCalculation: async (
    calculationId: string
  ): Promise<string[]> => {
    const path = `/controlzones/ids?calculationId=` + calculationId;
    const response = await request.get(path);

    return response as string[];
  },

  getZone: async (id: string): Promise<IZone> => {
    const path = `/controlzones/${id}`;
    const response = await request.get(path);
    return response as IZone;
  },

  getZonePolygon: async (id: string): Promise<IZonePolygon> => {
    const path = `/controlzones/${id}/polygon`;
    const response = await request.get(path);
    return response as IZonePolygon;
  },

  getZonePolygonBoundary: async (id: string): Promise<IZonePolygon> => {
    const path = `/controlzones/${id}/polygonboundary`;
    const response = await request.get(path);
    return response as IZonePolygon;
  },

  reimportData: async (): Promise<void> => {
    const path = "streets";
    await request.post(path, {});
  },

  findZones: async (calculationId: string): Promise<IZone[]> => {
    const path = `/controlzones?calculationId=${calculationId}`;
    const response = await request.get(path);
    return response as IZone[];
  },

  getSubdomains: async (): Promise<IZone[]> => {
    const path = "/controlzones/subdomains";
    const response = await request.get(path);

    return response as IZone[];
  },

  getSubAreaRules: async (): Promise<ISubAreaRuleListItem[]> => {
    const response = await request.get("/subarearules");
    return response as ISubAreaRuleListItem[];
  },

  getLastImport: async (): Promise<IImport> => {
    const response = (await request.get("/streets/lastImport")) as IImport;
    return response;
  },

  findAreas: async (query: FindAreasQuery): Promise<IArea[]> => {
    const stringified = queryString.stringify(query);
    const response = await request.get("/subareas?" + stringified);
    return response as IArea[];
  },

  getAllAreaSummaries: async (): Promise<IAreaSummary[]> => {
    const response = await request.get("/subareas/summaries");
    return response as IAreaSummary[];
  },

  getArea: async (id: string): Promise<IArea> => {
    const path = `/subareas/${id}`;
    const response = await request.get(path);
    return response as IArea;
  },

  getAreaZone: async (id: string): Promise<IAreaZone> => {
    const path = `/subareas/zone/${id}`;
    const response = await request.get(path);
    return response as IAreaZone;
  },

  getNoVehiclesAllowedAreas: async (): Promise<IArea[]> => {
    const path = `/subareas/novehiclesallowed`;
    const response = await request.get(path);
    return response as IArea[];
  },

  getTariffAreas: async (): Promise<IArea[]> => {
    const path = `/subareas/tariff`;
    const response = await request.get(path);
    return response as IArea[];
  },

  getRestrooms: async (): Promise<IRestroom[]> => {
    const path = `/restrooms`;
    const response = await request.get(path);
    return response as IRestroom[];
  },

  getStreetNames: async (): Promise<string[]> => {
    const response = await request.get("/streets/names");
    return response as string[];
  },

  findStreets: async (query: FindStreetsQuery): Promise<IStreet[]> => {
    const stringified = queryString.stringify(query);
    const path = "/streets?" + stringified;
    const response = await request.get(path);

    return response as IStreet[];
  },

  getStreetsInQuadrant: async (n: number): Promise<IStreet[]> => {
    const path = "/streets/subdomains/" + n;
    const response = await request.get(path);
    return response as IStreet[];
  },

  getStreets: async (): Promise<IStreetLocation[]> => {
    const path = "/streets/locations";
    const response = await request.get(path);
    return response as IStreetLocation[];
  },

  getSplitpoints: async (): Promise<ISplitPointLocation[]> => {
    const path = "/streets/splitpointLocations";
    const response = await request.get(path);
    return response as ISplitPointLocation[];
  },

  getStreetBoundaries: async (): Promise<ISplitPointLocation[]> => {
    const path = "/streets/boundaries";
    const response = await request.get(path);
    return response as ISplitPointLocation[];
  },

  createNewParameterset: async (
    command: CreateNewParameterSetCommand
  ): Promise<void> => {
    const path = `/parameters`;
    console.log(`call to ${path} with command:`, command);
    await request.post(path, command);
  },

  createClusterRule: async (
    command: CreateClusterRuleCommand
  ): Promise<void> => {
    const path = `/subarearules`;
    console.log(`call to ${path} with command:`, command);
    await request.post(path, command);
  },

  getClusterRuleZones: async (id: string): Promise<ISubAreaRuleSummary> => {
    const path = `/subarearules/${id}/zones`;
    const response = await request.get(path);
    return response as ISubAreaRuleSummary;
  },

  getDepots: async (): Promise<IDepot[]> => {
    const path = "/depots";
    const response = await request.get(path);
    return response as IDepot[];
  },

  getBikingPoints: async (
    query: FindBikingPointsQuery
  ): Promise<IBikingPoint[]> => {
    const stringified = queryString.stringify(query);
    const path = "/bikingpoints?" + stringified;
    const response = await request.get(path);
    return response as IBikingPoint[];
  },

  createCluster: async (
    name: string,
    streetIds: string[],
    geometry: IGeoPoint[][]
  ): Promise<void> => {
    const response = await request.post("/subareas", {
      name,
      streetIds,
      geometry,
    });
  },

  deleteArea: async (id: string): Promise<void> => {
    await request.del("/subareas/" + id);
  },

  deleteAreaRule: async (id: string): Promise<void> => {
    await request.del("/subarearules/" + id);
  },

  calculateCluster: async (polygon: IGeoPoint[]): Promise<IStreet[]> => {
    const path = "/subareas/calculate";
    const apiCommand: FindStreetsInPolygonCommand = {
      polygonGeoPoints: polygon,
    };
    const response = await request.post(path, apiCommand);
    return response as IStreet[];
  },

  getSanitairLocations: async (): Promise<IGeoPoint[]> => {
    const path = "/streets?restRoomsOnly=true";
    const response: IStreet[] = await request.get(path);
    const locations = response.map((r) => r.location);
    return locations;
  },

  getCalculations: async (
    query: FindCalculationsQuery
  ): Promise<ICalculation[]> => {
    const stringified = queryString.stringify(query);
    const path = "/calculations?" + stringified;
    const response = await request.get(path);
    return response as ICalculation[];
  },

  getCalculation: async (id: string): Promise<ICalculation> => {
    const path = `/calculations/${id}`;
    const response = await request.get(path);
    return response as ICalculation;
  },

  createPlanning: async (command: CreatePlanningCommand): Promise<void> => {
    const path = `/planning`;
    console.log(`call to ${path} with command:`, command);
    await request.post(path, command);
  },

  updatePlanning: async (command: UpdatePlanningCommand): Promise<void> => {
    const path = `/planning`;
    console.log(`call to ${path} with command:`, command);
    await request.patch(path, command);
  },

  // Retrieve all the planning objects for the given date.
  getPlannings: async (date: string): Promise<IPlanning[]> => {
    const path = `/planning?date=${date}`;
    return request.get(path);
  },

  getParameterSet: async (
    query: FindParameterSetQuery
  ): Promise<IParameterSet> => {
    const path = `/parameters/${query.parameterSetId}`;
    const response = await request.get(path);
    return response as IParameterSet;
  },

  /* getPriorityStreets: async (
    query: FindPriorityStreetsQuery
  ): Promise<IPriorityStreet> => {
    const path = `/streets?parametersetId=${query.parameterSetId}&prioritylevel=most`;
    const response = await request.get(path);
    return response as IPriorityStreet[];
  }, */

  updateParameterSet: async (
    id: string,
    command: UpdateParameterSetCommand
  ): Promise<void> => {
    const path = `/parameters/${id}`;
    await request.patch(path, command);
  },

  deleteParameterSet: async (id: string): Promise<void> => {
    const path = `/parameters/${id}`;
    await request.del(path);
  },

  setParameterSetInProduction: async (
    command: CreateNewParameterSetCommand
  ): Promise<void> => {
    const path = `/parameters/production`;
    await request.post(path, command);
  },

  getParameterSetInProduction: async (): Promise<IActiveParameterSet[]> => {
    const path = `/parameters/activeProduction`;
    const response = await request.get(path);
    return response as IActiveParameterSet[];
  },

  checkSecurity: async (): Promise<boolean> => {
    const path = `/security`;
    try {
      await request.get(path);
      return true;
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 401) {
        console.error(error);

        return false;
      }

      throw error;
    }
  },
};
