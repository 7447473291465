import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("@/views/Index.vue"),
      children: [
        {
          name: "planner",
          path: "planner",
          component: () => import("@/views/planner/Planner.vue")
        },
        {
          name: "planner-calculations",
          path: "planner/calculations",
          component: () => import("@/views/planner/PlannerCalculations.vue")
        },
        {
          name: "calculations",
          path: "",
          props: true,
          component: () => import("@/views/zones/History.vue")
        },
        {
          name: "calculations-compare",
          path: "zones/compare",
          component: () => import("@/views/zones/Compare.vue")
        },
        {
          name: "zones",
          path: "zones/:id",
          props: true,
          component: () => import("@/views/zones/Zones.vue")
        },
        {
          name: "zone",
          path: "zones/zone/:id",
          component: () => import("@/views/zones/Zone.vue")
        },
        {
          name: "edit-calculation",
          path: "parametersets",
          component: () => import("@/views/parametersets/Parametersets.vue")
        },
        {
          name: "new-calculation",
          path: "parametersets",
          component: () => import("@/views/parametersets/Parametersets.vue")
        },
        {
          name: "areas",
          path: "areas",
          component: () => import("@/views/areas/Areas.vue")
        },
        {
          name: "new-area",
          path: "areas/create",
          component: () => import("@/views/areas/CreateArea.vue")
        },
        {
          name: "area",
          path: "areas/:id",
          component: () => import("@/views/areas/Area.vue")
        },
        {
          name: "cluster-rules",
          path: "rules",
          component: () => import("@/views/clusterrules/Clusterrules.vue")
        },
        {
          name: "new-cluster-rule",
          path: "rules/create",
          component: () => import("@/views/clusterrules/CreateClusterRule.vue")
        },
        {
          name: "cluster-rule",
          path: "rules/:id",
          component: () => import("@/views/clusterrules/Clusterrule.vue")
        },
        {
          name: "datasets",
          path: "datasets",
          component: () => import("@/views/datasets/Datasets.vue")
        },
        {
          name: "points-of-interest",
          path: "datasets/poi",
          component: () => import("@/views/datasets/PointOfInterestMap.vue")
        }
      ]
    }
  ]
});
