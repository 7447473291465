import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";
import "@/sass/overrides.sass";

Vue.use(Vuetify);

const theme = {
  primary: "#2096f3", // '#4CAF50',
  secondary: "#9C27b0",
  accent: "#9C27b0",
  info: "#00CAE3",
  background: "#EEEEEE"
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    options: { customProperties: true }, // TODO: check on IE11
    themes: {
      dark: theme,
      light: theme
    }
  }
});
